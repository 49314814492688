@use '@angular/material' as mat;
$border-radius: 0.4rem;
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.3rem;
//colores
$primary: #1446A3;
$secondary: #FF5F0D;
$success: #06AB3E;
$info: #009DF5;
$warning: #FF9A00;
$danger: #FA391F;
$light: #F6F6F6;
$dark: #16181D;
$text-muted: rgba($dark, 0.6);
$superLight: rgba($dark, 0.1);
$white: #fff;

//buttons
$btn-border-width: 2px;
$btn-font-weight: 600;
$btn-padding-y: 0.8rem;
$btn-padding-x: 1.3rem;
$btn-padding-y-sm: 0.4rem;
$btn-padding-x-sm: 0.75rem;
$btn-border-radius: 0.4rem;
$btn-border-radius-sm: 0.3rem;
$btn-border-radius-lg: 0.5rem;

// Espacios
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.75,
  ),
  3: (
    $spacer * 1.3,
  ),
  4: (
    $spacer * 2,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 5,
  ),
  7: (
    $spacer * 7,
  ),
  8: (
    $spacer * 10,
  ),
  9: (
    $spacer * 14,
  ),
  10: (
    $spacer * 20,
  ),
);

$enable-cssgrid: true;

// Bootstrap 5
@import "../node_modules/bootstrap/scss/bootstrap.scss";

// Swiper Angular
// @import "swiper/swiper.scss";
// @import "~swiper/swiper-bundle";

// Fonts Google
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400&display=swap');

// Custom theme Angular Material
$custom-typography: mat.define-typography-config(
  $font-family: "Poppins" !important,
);
@include mat.core($custom-typography);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins";
  color: $dark;
}

a, .btn-link {
  text-decoration: none;
}

.logoSeguros360 {
  width: 300px;
  @include media-breakpoint-only(xs) {
    width: 180px;
  }
}


// .myBtn {
//   padding: 0.9rem 1.8rem;
// }

*:focus {
  outline: 0px !important;
  // box-shadow: none !important;
}

.backBannerHome {
  background: url('https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/backSala.png?alt=media&token=127989ed-2828-4858-8c5f-f745d0126b0a'), $primary;
  background-blend-mode: overlay, normal;
  background-position: center bottom;
  background-size: cover;
}

.myBlurBack {
  background: rgba($primary, 0.5);
  backdrop-filter: blur(4px);

}

.imgBanner {
  height: 645px;
  @include media-breakpoint-only(xs) {
    width: 70%;
    height: auto;
  }
}

.myBoxCotizadorHome {
  border: 3px solid rgba($white, 0.25);
  border-radius: 1.5rem;
  .gorro {
    letter-spacing: 3px;
  }
}

.myBoxCotizadorHome:hover {
  border: 3px solid rgba($white, 1);
}

.categoriaHome {
  background: linear-gradient(90deg, rgba(245, 245, 245, 0.5) 9.17%, rgba(245, 245, 245, 0) 100%), url('https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/bannerCategorias%2FbackAbstract.jpg?alt=media&token=fafb7d5e-72b5-4293-964f-baf3685c69bc');
  background-size: cover;
  background-position: center center;
  border-radius: 1.5rem;
}

.imgCategoriaHome{
  width: 100%;
  height: auto;
  
  // @include media-breakpoint-only(xs) {
  //   width: 300px;
  //   height: auto;
  // }
}

.imgCategoriaSeguros{
  width: 100%;
  height: auto;

}

.mat-stepper-horizontal {
  background: none !important;
}

.myButtonCheckboxModal {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  white-space: normal;
  word-break: normal;
}





.mat-expansion-panel {
  border-radius: 0 !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  // box-shadow: none;
  box-shadow: 0px 8px 16px rgba($primary, 0.1);
  border: 1px solid rgba($dark, 0.1);
  color: inherit;
}

// Custom Mat acordion

.mat-expansion-panel-header {
  padding: 3rem 2rem 3rem 1rem !important;
  @include media-breakpoint-only(xs) {
    padding: 2.3rem 1.5rem 2.3rem 0.75rem !important;
  }
}

.mat-expansion-panel-header-title {
  display: inline !important;
  align-items: center;
  margin-right: 5rem !important;
  @include media-breakpoint-only(xs) {
    margin-right: 0.25rem !important;
  }
}

.mat-expansion-panel-header-description {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-only(xs) {
    display: inline !important;
  }
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 0rem !important;
  margin-bottom: -1px;
}

.mat-expansion-panel-spacing {
  margin: 0.5rem 0 !important;
}

.primerItemAcordion {
  .mat-expansion-panel:not(.mat-expanded) {
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
}

.ultimoItemAcordion {
  .mat-expansion-panel:not(.mat-expanded) {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $dark;
}

.myBodyAcordion {
  .mat-expansion-panel-body {
    padding: 0rem !important;
  }
}

// .myAccordion {
//   background-color: white;
//   border-radius: 1rem;
//   box-shadow: 0px 8px 16px rgba($primary, 0.1);
//   // padding: 2rem !important;
//   @include media-breakpoint-only(xs) {
//     padding: 1rem !important;
//   }
// }

.planSalud {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  line-height: 120%;
  @include media-breakpoint-only(xs) {
    font-size: 1rem;
  }
}

.primaSalud {
  font-size: 1.1rem;
  font-weight: 700;
  white-space: nowrap;
  line-height: 120%;
  @include media-breakpoint-only(xs) {
    font-size: 1rem;
  }
}

.extraSmall {
  font-size: 0.78rem;
  line-height: 120%;
}

.superExtraSmall {
  font-size: 0.7rem;
}

.logoResultadoSalud {
  width: 6rem;
  @include media-breakpoint-only(xs) {
    width: 4.5rem;
  }
}

.listaCaracteristicas {
  // ul {
  //   padding-left: 1rem;
  //   margin-bottom: 0rem;
  //   li {
  //     font-size: 0.8rem;
  //     line-height: 100%;
  //     margin-bottom: 6px;
  //   }
  // }
  font-size: 0.8rem;
  line-height: 110%;
  margin-bottom: 0.5rem;
}

.titleCategoriaSalud {
  font-size: 1.4rem;
  font-weight: 300;
  margin-top: 3.4rem;
  margin-bottom: 0.5rem;
  //  background-color: rgba($primary, 0.1);
  display: inline-block;
  //  padding: 0rem 0.5rem;
  @include media-breakpoint-only(xs) {
    font-size: 1.2rem;
    margin-top: 2.7rem;
  }
}

.modalDetalleSeguroSalud {
  @include media-breakpoint-only(xs) {
    max-width: none !important;
    padding: 0rem !important;
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0rem !important;
  }
  .mat-dialog-container {
    padding: 0rem;
  }
}

.modalContactoSeguroSalud {
  max-width: 480px !important;
  @include media-breakpoint-only(xs) {
    max-width: none !important;
    padding: 0rem !important;
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0rem !important;
  }
  .mat-dialog-container {
    padding: 0rem;
  }
}

.myTab {
  .mat-tab-label {
    padding: 0 0.65rem !important;
  }
}

.buttonClose {
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
}


.terminosCheckbox {
  .mat-checkbox-label {
    font-size: 0.75rem;
    line-height: 120% !important;
  }
}

.mat-dialog-container {
  position: relative;
  // padding: 2.6rem !important;
  border-radius: 0.75rem !important;
  @include media-breakpoint-only(xs) {
    border-radius: inherit !important;
    // padding: 2rem !important;
  }
}

.imgSinPreocupaciones {
  width: 70%;
  height: auto;
  @include media-breakpoint-only(xs) {
    height: 300px;
    width: auto;
  }
}

.grillaSocios {
  display: grid;
  gap: 7rem;
  grid-template-columns: repeat(3, 1fr);
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
}

.grillaTestimonios {
  display: grid;
  gap: 6rem;
  grid-template-columns: repeat(3, 1fr);
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
}

.btn-circle {
  width: 50px;
  height: 50px;
  padding: 6px 6px;
  border: none;
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-outline-light:hover {
  .icon {
    stroke: $primary;
  }
}
.btn-outline-secondary:hover {
  .icon {
    stroke: $dark;
  }
}
// .extraSmall {
//   font-size: 0.80rem;
//   line-height: 100%;
// }

// .superExtraSmall {
//   font-size: 0.68rem;
// }

.imageSeguroDetalle {
  width: 100%;
  height: 420px;
  background-size: cover;
  background-position: center center;
  @include media-breakpoint-only(xs) {
    height: 200px;
  }
}

.bannerNosotros {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/nosotros.jpg?alt=media&token=905a6bcf-96fc-4066-97b8-d31e73940864');
  background-position: center center;
  background-size: cover;
  height: 420px;
  @include media-breakpoint-only(xs){
    height: 200px;
  }
}

.grillaLogosSoatAuto {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.5rem;
  row-gap: 0.2rem;
}

.backCotizador {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/backCotizador.jpg?alt=media&token=24ebb2bf-3193-4e9e-a6cc-69afc03871e3');
  background-repeat: repeat-y;
  background-position: center;
}

.backVida {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/backVida.jpg?alt=media&token=63b62f19-0268-46cf-b0e4-cf041636d9e0');
  background-repeat: repeat-y;
  background-position: center;
}

.myColorSpinner {
  .mat-spinner circle {
    stroke: white;
  }
}

.listaTerminosCondiciones {
  li {
    margin-bottom: 20px;
  }
}

.btn-circle-sm {
  width: 38px;
  height: 38px;
  padding: 0px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  i-tabler {
    width: 24px;
    height: 24px;
    stroke-width: 2;
  }
}

.btn-circle-lg {
  width: 54px;
  height: 54px;
  padding: 6px 6px;
  border-radius: 30px;
  border: none;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.42857;
}

.myPositionBottom {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  @include media-breakpoint-only(xs) {
    padding-right: 0.9rem;
    padding-bottom: 0.9rem;
  }
}

.aseguradoraSoat {
  width: 140px;
  @include media-breakpoint-only(xs) {
    width: 140px;
  }
}

.itemSoat:hover {
  border: 2px solid rgba($success, 1) !important;
  transition: 150ms;
}

.grillaAseguradorasHome {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
}

.imageBeneficioVida {
  object-fit: cover;
  width: 280px;
  height: 280px;
}

// .mat-icon-button .mat-button-wrapper {
//   line-height: 100% !important;
//   display: flex !important;
//   justify-content: center !important;
// }


.buttonDotted {
  border: dashed 2px rgba($primary, 0.5);
  background-color: rgba($primary, 0);
  aspect-ratio: 21 / 9 ;
  i-tabler {
    width: 90px;
    height: 90px;
    stroke-linecap: square;
    stroke-width: 0.75;
  }
}

.buttonDotted:hover {
  background-color: rgba($primary, 0.2);
}


.imageItemPreview {
  // width: 100%;
  aspect-ratio: 21 / 9;
  background-position: center center;
  background-size: cover;
}

table {
  width: 100% !important;
}

.imageNoticiaAdmin {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.imageNoticiaCardHome {
  width: 100%;
  height: 220px;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    height: 180px;
  }
}

.imageNoticiaCard {
  width: 100%;
  height: 320px;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    height: 180px;
  }
}

.imageDetalleNoticiaHel {
  height: 380px;
  width: 100%;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    height: 240px;
  }
}

.bodyNoticia {
  img {
    width: 100%;
    height: auto;
  }
  p {
    line-height: 160%;
    font-size: 1.1rem;
    // font-family: 'Merriweather';
    font-weight: 400;
    padding-bottom: 1rem;
  }
  li {
    line-height: 160%;
    font-size: 1.1rem;
  }
}

.breadcrumb {
  margin-top: 15px;
}